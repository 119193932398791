:root {
  --light-blue: #568a80;
  --dark-blue: #234350;
  --earth: #ad8857;

  --skin: #efe9d9;

  --white: #fff;
  --black: #000;
  --red: #dd4b3c;

  /* 4 mau thanh nhe */
  --light-green: #b4cab5;
  --light-orange: #f3cb96;
  --pink: #e49f9c;
  --light-pink: #f0c1bd;
}

/* var(--skin) */
