@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto/Roboto-Bold.ttf),
    url(../fonts/Roboto/Roboto-Thin.ttf),
    url(../fonts/Roboto/Roboto-Regular.ttf),
    url(../fonts/Roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: Noto_Serif_KR;
  src: url(../fonts/Noto_Serif_KR/NotoSerifKR-Bold.otf),
    url(../fonts/Noto_Serif_KR/NotoSerifKR-Light.otf),
    url(../fonts/Noto_Serif_KR/NotoSerifKR-Regular.otf),
    url(../fonts/Noto_Serif_KR/NotoSerifKR-Medium.otf);
}

@font-face {
  font-family: Noto_Sans_KR;
  src: url(../fonts/Noto_Sans_KR/NotoSansKR-Bold.otf),
    url(../fonts/Noto_Sans_KR/NotoSansKR-Thin.otf),
    url(../fonts/Noto_Sans_KR/NotoSansKR-Regular.otf),
    url(../fonts/Noto_Sans_KR/NotoSansKR-Medium.otf);
}
