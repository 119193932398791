@import "./lib/css/reset.css";
@import "./lib/css/grid.css";
@import "./lib/css/font.css";
@import "./lib/css/color.css";

body {
  font-family: "Noto_Serif_KR", "Noto_Sans_KR", "Roboto";
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

  font-size: 16px;
}

.display-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.centerize {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-space-around {
  justify-content: space-around;
}
