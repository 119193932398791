.row {
  width: 100%;
  display: flex;
}

.col-6 {
  width: 50%;
}

.col-4 {
  width: 33.33%;
}

.container {
  margin: 0 auto;
  position: relative;
}

@media (max-width: 575px) {
  .container {
    width: 100%;
  }
  .row {
    flex-direction: column;
  }
  .col-6 {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .container {
    width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1320px;
  }
}
